<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12" xl="4" md="6" class="mb-2">
        <div class="input-group">
          <span class="input-group-text input-group-text--addon-left">
            <b-icon icon="search"></b-icon>
          </span>
          <input
            v-model="filters.name"
            v-on:keyup="search"
            type="text"
            class="form-control form-control--addon-left form-control--h46px pl-48px mw-324px rounded-10px"
            placeholder="搜索系列名稱"
          >
        </div>
      </b-col>
      <b-col cols="12" xl="2" md="6" class="mb-2">
         <b-button
          @click="showCreateModal"
          class="btn btn-secondary btn--h46px justify-content-center w-100"
        >
          新創建
        </b-button>
      </b-col>
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-start"
          v-model="filters.start"
          @input="getAll"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
      <b-col cols="12" xl="3" md="6" class="mb-2">
        <b-form-datepicker
          id="datepicker-end"
          v-model="filters.end"
          @input="getAll"
          class="b-form--datepicker form-control--h46px"
          today-button
          reset-button
          close-button
          locale="zh"
          placeholder="2021 年 11 月 10 日"
        ></b-form-datepicker>
      </b-col>
    </b-row>

    <div class="card border-0 shadow mb-4">
      <div class="table-responsive mb-0">
      <table class="table table-hover">
        <thead class="bg-brown--light">
          <tr>
            <th class="text-center text-nowrap"></th>
            <th class="text-center text-nowrap">排序序號</th>
            <th class="text-nowrap">系列編號</th>
            <th class="text-center text-nowrap">預覽系列相片</th>
            <th class="text-center text-nowrap">系列名稱</th>
            <th class="text-center text-nowrap">系列名称 (简体版本)</th>
            <th class="text-center text-nowrap">創建日期</th>
            <th class="text-center text-nowrap">狀態</th>
            <th class="text-center text-nowrap">操作</th>
          </tr>
        </thead>
        <draggable tag="tbody" :list="categories.content"  @end="handleDragEnd" :disabled='disabledDraggable'>
          <tr v-for="item in categories.content" :key="item.id" :class="{'cursor-move': !disabledDraggable}">
            <td class="align-middle text-center text-nowrap">
              <div class="d-flex align-items-center"><IconSortingOrder :class="disabledDraggable ? 'opacity-25': 'opacity-100'" style="height: 18px; width:18px;"/></div>
            </td>
            <td class="align-middle text-center text-nowrap">
              {{item.sortingOrder}}
            </td>
            <th scope="row" class="align-middle font-weight-normal text-center text-nowrap">
              {{item.posId}}
            </th>
            <td class="text-center">
              <img class="rounded-circle" width="66" height="66" v-bind:src="item.imageUrl" />
            </td>
            <td class="align-middle text-center text-nowrap">{{item.nameZhHk}}</td>
            <td class="align-middle text-center text-nowrap">{{item.nameZhCn}}</td>
            <td class="align-middle text-center text-nowrap">{{item.createdDate | formatDate}}</td>
            <td class="align-middle text-center text-nowrap">
              <template>
                <div v-if="item.active == true" class="text-success">啟用</div>
                <div v-else-if="item.active == false" class="text-danger">停用</div>
              </template>
            </td>
            <td class="align-middle text-center text-nowrap">
              <b-dropdown boundary="viewport" variant="link" toggle-class="text-white text-decoration-none" no-caret>
                <template #button-content>
                  <b-icon icon="three-dots" font-scale="1.5" class="text-dark"></b-icon>
                </template>

                <b-dropdown-item @click="showEditModal(item.id)">編輯</b-dropdown-item>
                <b-dropdown-item
                  :disabled="item.active == false"
                  v-on:click="handleDeactivate(item.id)"
                >
                  停用
                </b-dropdown-item>
                <b-dropdown-item @click="showSortingModal(item)">排序順序</b-dropdown-item>
                <b-dropdown-item
                  :disabled="item.active == true"
                  v-on:click="handleActivate(item.id)"
                >
                  啟用
                </b-dropdown-item>
                <b-dropdown-item
                  v-on:click="handleDelete(item.id)"
                >
                  刪除
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </draggable>
      </table>
      </div>
    </div>

    <nav aria-label="Categories pagination">
      <ul class="pagination">
        <li class="page-item" :class="{ 'disabled active': categories.first }">
          <a
            v-on:click="handlePaginate(categories.number - 1)"
            class="page-link"
            href="#"
          >
            以前的
          </a>
        </li>
        <li
          v-for='pageNo in categories.totalPages'
          :key="pageNo"
          class="page-item"
          v-bind:class="{ 'disabled active': ((pageNo - 1) === categories.number) }"
        >
          <a
            v-on:click="handlePaginate(pageNo - 1)"
            class="page-link"
            href="#"
          >{{ pageNo }}</a>
        </li>

        <li class="page-item" :class="{ 'disabled active': categories.last }">
          <a
            v-on:click="handlePaginate(categories.number + 1)"
            class="page-link"
            href="#"
          >
            下一頁
          </a>
        </li>
      </ul>
    </nav>

    <!-- Create Category Modal -->
    <b-modal ref="createCategoryModal" centered size="lg" no-close-on-backdrop hide-header hide-footer>
      <b-alert show variant="danger" v-if="veeErrors.all().length">
        <ul class="list-unstyled mb-0">
          <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">{{ error }}</li>
        </ul>
      </b-alert>

      <b-form @submit.stop.prevent="onSubmitCreateCategory" class="p-3 p-lg-5">
        <h5 class="text-brown">創建產品系列</h5>

        <div class="mb-5">
          <b-row class="mb-3">
            <b-col sm="6" lg="4" class="order-2 order-lg-1">
              <div
                class="preview-image d-flex align-items-center justify-content-center text-brown border shadow-sm"
                :style="{ 'background-image': `url(${previewImage})` }"
              >
                預覽產品相片
              </div>
            </b-col>

            <b-col sm="6" lg="4" class="order-3 order-lg-2 mt-3 mt-lg-4">
              <b-form-file
                id="previewImage"
                class="d-none"
                ref="fileImage"
                name="image"
                v-validate="{ required: false, ext:'jpeg|jpg|png', size:1024 }"
                :state="validateState('image')"
                aria-describedby="input-image-live-feedback"
                data-vv-as="image"
                @input="browseImage"
              ></b-form-file>
              <div class="mb-3">
                <label for="previewImage" class="bg-white text-brown border border-brown--light rounded-6px py-2 px-4">上傳產品相片</label>
              </div>
              <div>
                <div class="mb-1">只接受 jpg,jpeg,png格式</div>
                <div class="mb-1">建議尺寸160x160 px</div>
                <div class="mb-1">最大上傳檔案 1Mb</div>
              </div>
            </b-col>

            <b-col sm="4" lg="2" class="order-1 order-lg-3 mb-2 mt-3 mt-lg-4">
              <div class="float-lg-right">
                <div class="d-flex align-items-center">
                  <div class="mr-2">啟用</div>
                  <b-form-checkbox
                    name="check-button"
                    class="custom-switch--success"
                    switch
                    v-model="formControl.active"
                    :checked="formControl.active"
                    ></b-form-checkbox>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="posId" class="text-brown">系列編號 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8" lg="6">
              <b-form-input
                id="posId"
                class="rounded-0"
                name="posId"
                v-model="formControl.posId"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('posId')"
                aria-describedby="input-posId-live-feedback"
                data-vv-as="posId"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhHk" class="text-brown">系列名稱 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8" lg="6">
              <b-form-input
                id="nameZhHk"
                class="rounded-0"
                name="nameZhHk"
                v-model="formControl.nameZhHk"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('nameZhHk')"
                aria-describedby="input-nameZhHk-live-feedback"
                data-vv-as="nameZhHk"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhCn" class="text-brown">系列名称 (简体版本)</label>
            </b-col>
            <b-col sm="8" lg="6">
              <b-form-input
                id="nameZhCn"
                class="rounded-0"
                name="nameZhCn"
                v-model="formControl.nameZhCn"
                v-validate="{ required: false }"
                :state="validateState('nameZhCn')"
                aria-describedby="input-nameZhCn-live-feedback"
                data-vv-as="nameZhCn"
              ></b-form-input>
            </b-col>
          </b-row>
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <b-button @click="hideCreateModal" class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4">取消</b-button>
          <b-button :disabled="!hasVeeErrors || isSubmitting" type="submit" variant="primary" class="rounded-10px px-3 px-lg-5">
            <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
              >儲存</b-button>
        </div>
      </b-form>
    </b-modal>

    <!-- Edit Category Modal -->
    <b-modal ref="editCategoryModal" centered size="lg" no-close-on-backdrop hide-header hide-footer>
      <b-alert show variant="danger" v-if="veeErrors.all().length">
        <ul class="list-unstyled mb-0">
          <li v-for="error in filterVeeErrors(veeErrors.all())" :key="error">{{ error }}</li>
        </ul>
      </b-alert>

      <b-form @submit.stop.prevent="onSubmitUpdateCategory" class="p-3 p-lg-5">
        <h5 class="text-brown">創建產品系列</h5>

        <div class="mb-5">
          <b-row class="mb-3">
            <b-col sm="6" lg="4" class="order-2 order-lg-1">
              <div
                class="preview-image d-flex align-items-center justify-content-center text-brown border shadow-sm"
                :style="{ 'background-image': `url(${previewEditImage})` }"
              >
                預覽產品相片
              </div>
            </b-col>

            <b-col sm="6" lg="4" class="order-3 order-lg-2 mt-3 mt-lg-4">
              <b-form-file
                id="previewEditImage"
                class="d-none"
                ref="fileEditImage"
                name="image"
                v-validate="{ required: false, ext:'jpeg|jpg|png', size:1024 }"
                :state="validateState('image')"
                aria-describedby="input-image-live-feedback"
                data-vv-as="image"
                @input="browseEditImage"
              ></b-form-file>
              <div class="mb-3">
                <label for="previewEditImage" class="bg-white text-brown border border-brown--light rounded-6px py-2 px-4">上傳產品相片</label>
              </div>
              <div>
                <div class="mb-1">只接受 jpg,jpeg,png格式</div>
                <div class="mb-1">建議尺寸160x160 px</div>
                <div class="mb-1">最大上傳檔案 1Mb</div>
              </div>
            </b-col>

            <b-col sm="4" lg="2" class="order-1 order-lg-3 mb-2 mt-3 mt-lg-4">
              <div class="float-lg-right">
                <div class="d-flex align-items-center">
                  <div class="mr-2">啟用</div>
                  <b-form-checkbox
                    name="check-button"
                    class="custom-switch--success"
                    switch
                    v-model="formControl.active"
                    :checked="formControl.active"
                    ></b-form-checkbox>
                </div>
              </div>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="posId" class="text-brown">系列編號 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8" lg="6">
              <b-form-input
                id="posId"
                class="rounded-0"
                name="posId"
                v-model="formControl.posId"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('posId')"
                aria-describedby="input-posId-live-feedback"
                data-vv-as="posId"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhHk" class="text-brown">系列名稱 <span class="text-danger">*</span></label>
            </b-col>
            <b-col sm="8" lg="6">
              <b-form-input
                id="nameZhHk"
                class="rounded-0"
                name="nameZhHk"
                v-model="formControl.nameZhHk"
                v-validate="{ required: true, max: 100 }"
                :state="validateState('nameZhHk')"
                aria-describedby="input-nameZhHk-live-feedback"
                data-vv-as="nameZhHk"
              ></b-form-input>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col sm="4">
              <label for="nameZhCn" class="text-brown">系列名称 (简体版本)</label>
            </b-col>
            <b-col sm="8" lg="6">
              <b-form-input
                id="nameZhCn"
                class="rounded-0"
                name="nameZhCn"
                v-model="formControl.nameZhCn"
                v-validate="{ required: false }"
                :state="validateState('nameZhCn')"
                aria-describedby="input-nameZhCn-live-feedback"
                data-vv-as="nameZhCn"
              ></b-form-input>
            </b-col>
          </b-row>
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <b-button @click="hideEditModal" class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4">取消</b-button>
          <b-button :disabled="!hasVeeErrors || isSubmitting" type="submit" variant="primary" class="rounded-10px px-3 px-lg-5">
            <b-spinner v-if="isSubmitting" small class="mr-2"></b-spinner
              >儲存</b-button>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="setSortingOrder"
      centered
      no-close-on-backdrop
      hide-footer
      hide-header
      size="lg"
    >
      <b-form class="p-3" @submit.prevent="submitChangeSorting(+selectedProSeries.sortingOrder, +sortingOrder)">
        <h5 class="text-brown mb-4">更改產品的排序順序</h5>
        <div>
        <b-row class="mb-3" v-if="!!this.selectedProSeries">
        <b-col sm="6" lg="4">
          <div class="d-flex justify-content-center align-items-center" style="height: 160px; width: 100%;">
             <img :src="this.selectedProSeries.imageUrl" class="img-fluid rounded-sm shadow-sm" style="object-fit: cover; width: 100%; height: 100%;">
          </div>
        </b-col>
        <b-col sm="6" lg="8" class="align-self-center">
          <p><span>產品系列 : </span> {{ this.selectedProSeries.posId }}</p>
          <p><span>產品名稱 : </span>{{ this.selectedProSeries.nameZhHk }}</p>
          <p><span>系列名称 (简体版本) : </span> {{ this.selectedProSeries.nameZhCn }}</p>
        </b-col>
      </b-row>
        </div>
        <b-form-input v-model="sortingOrder" type="number"></b-form-input>
        <p class="mt-2 text-primary" style="font-size: 11px;">*排序編號超過可用產品系列將被調整到最大的可用位置。</p>
        <div class="d-flex mt-4 align-items-center justify-content-end">
          <b-button
            @click="hideSortingModal()"
            class="btn btn-warning rounded-10px px-3 px-lg-5 text-white mr-2 me-lg-4"
            >關閉</b-button
          >
          <b-button
            :disabled="!sortingOrder"
            type="submit"
            variant="primary"
            class="rounded-10px px-3 px-lg-5"
            >保存</b-button
          >
        </div>
      </b-form>
    </b-modal>
  </b-container>
</template>

<script>
import debounce from 'lodash/debounce'
import {
  getCategories,
  getCategory,
  createCategory,
  updateCategory,
  deactivateCategory,
  activateCategory,
  deleteCategory,
  updateSortingOrderForProductSeries
} from '@/services/category.service'
import draggable from 'vuedraggable'
import IconSortingOrder from '@/components/icons/IconSortingOrder.vue'

export default {
  name: 'Category',

  data: () => ({
    filters: {
      name: '',
      start: '',
      end: '',
      size: 10,
      page: 0
    },
    categories: [],
    categoryID: null,
    previewImage: null,
    previewEditImage: null,
    formControl: {
      active: true,
      posId: '',
      image: null,
      nameZhHk: '',
      nameZhCn: ''
    },
    sortingOrder: null,
    selectedProSeries: null,
    isSubmitting: false
  }),
  components: {
    draggable,
    IconSortingOrder
  },
  mounted () {
    this.getAll()
  },

  computed: {
    hasVeeErrors: ({ veeErrors }) => veeErrors.all().length === 0,
    disabledDraggable  () {
      return this.filters.name || this.filters.start || this.filters.end
    }
  },

  methods: {
    isSortingOrderInSequence (array) {
      if (!Array.isArray(array) || array.length === 0) return true

      array.sort((a, b) => {
        if (a.sortingOrder !== b.sortingOrder) {
          return a.sortingOrder - b.sortingOrder
        } else {
          return new Date(b.createdDate) - new Date(a.createdDate)
        }
      })
      for (let i = 0; i < array.length; i++) {
        const expectedOrder = (this.filters.page * this.filters.size) + (i + 1)
        if (array[i].sortingOrder !== expectedOrder) {
          return false
        }
      }
      return true
    },

    sortCategoriesByOrder (categories) {
      return categories.sort((a, b) => a.sortingOrder - b.sortingOrder)
    },

    mapUpdatedSortingOrder (sortedCategories) {
      return sortedCategories.map((item, idx) => ({
        productSeriesId: item.id,
        sortingOrder: (this.filters.page * this.filters.size) + (idx + 1)
      }))
    },

    async handleRearrangeOrder () {
      const data = this.categories.content
      const isSortingOrderInSequence = this.isSortingOrderInSequence(data)
      if (!isSortingOrderInSequence) {
        const sortedCategories = this.sortCategoriesByOrder(data)
        const mapUpdatedItems = this.mapUpdatedSortingOrder(sortedCategories)
        // Only perform the update if necessary
        await updateSortingOrderForProductSeries(mapUpdatedItems).then((res) => {
          if (res.success) {
            this.getAll()
          }
        })
      }
    },

    async getAll () {
      await getCategories(this.filters).then(
        async (res) => {
          this.categories = res.data.data
          // Rearrange order only after fetching data
          await this.handleRearrangeOrder()
        },
        (err) => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    search: debounce(function () {
      this.filters.page = 0
      this.getAll()
    }, 250),
    handlePaginate (pageNo) {
      this.filters.page = pageNo
      getCategories(this.filters).then(
        async (res) => {
          this.categories = res.data.data
          await this.handleRearrangeOrder()
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    validateState (ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref)
      }
      return null
    },
    filterVeeErrors (veeErrors = []) {
      return veeErrors.filter((element, index) => {
        return veeErrors.indexOf(element) === index
      })
    },
    async onSubmitCreateCategory () {
      this.$validator.validateAll().then(async (result) => {
        if (!result) {
          return
        }

        const createFormData = new FormData()
        this.isSubmitting = true
        createFormData.append('active', this.formControl.active)
        if ((this.formControl.image != null && this.formControl.image !== undefined) && typeof this.formControl.image !== 'string') {
          createFormData.append('image', this.formControl.image)
        }
        createFormData.append('posId', this.formControl.posId)
        createFormData.append('nameZhHk', this.formControl.nameZhHk)
        createFormData.append('nameZhCn', this.formControl.nameZhCn)

        await createCategory(createFormData).then(
          async (res) => {
            if (res.data.success) {
              console.log(res.data)
              const productSeriesId = res.data.data.id
              await updateSortingOrderForProductSeries([{ productSeriesId, sortingOrder: 1 }])
              this.hideCreateModal()
              this.filters.page = 0
              this.isSubmitting = false
              this.getAll()
              this.makeToast('success', '產品系列', '儲存成功')
            }
          },
          err => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
            this.isSubmitting = false
          }
        )
      })
    },
    onSubmitUpdateCategory () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return
        }

        const updateFormData = new FormData()
        this.isSubmitting = true
        updateFormData.append('active', this.formControl.active)
        if ((this.formControl.image != null && this.formControl.image !== undefined) && typeof this.formControl.image !== 'string') {
          updateFormData.append('image', this.formControl.image)
        }
        updateFormData.append('posId', this.formControl.posId)
        updateFormData.append('nameZhHk', this.formControl.nameZhHk)
        updateFormData.append('nameZhCn', this.formControl.nameZhCn)

        updateCategory(this.categoryID, updateFormData).then(
          res => {
            if (res.data.success) {
              this.hideEditModal()
              this.isSubmitting = false
              this.getAll()
              this.makeToast('success', '產品系列', '更新成功')
            }
          },
          err => {
            this.makeToast('danger', '發生錯誤，請稍後再試。', err)
            this.isSubmitting = false
          }
        )
      })
    },
    handleDeactivate (id) {
      deactivateCategory(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '產品系列', '停用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleActivate (id) {
      activateCategory(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '產品系列', '啟用成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    handleDelete (id) {
      deleteCategory(id).then(
        res => {
          if (res.data.success) {
            this.getAll()
            this.makeToast('success', '產品系列', '刪除成功')
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    makeToast (variant = null, title = 'Title', message = 'Message!') {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 3000,
        variant: variant,
        solid: true
      })
    },

    browseImage () {
      const selectdImageInput = this.$refs.fileImage.files[0]
      if (selectdImageInput) {
        const reader = new FileReader()
        reader.onload = e => {
          this.previewImage = e.target.result
          this.formControl.image = selectdImageInput
        }
        reader.readAsDataURL(selectdImageInput)
        this.$emit('input', selectdImageInput)
      }
    },
    browseEditImage () {
      const selectdImageInput = this.$refs.fileEditImage.files[0]
      if (selectdImageInput) {
        const reader = new FileReader()
        reader.onload = e => {
          this.previewEditImage = e.target.result
          this.formControl.image = selectdImageInput
        }
        reader.readAsDataURL(selectdImageInput)
        this.$emit('input', selectdImageInput)
      }
    },

    showCreateModal () {
      this.$refs.createCategoryModal.show()
    },
    hideCreateModal () {
      this.resetformControl()
      this.$refs.createCategoryModal.hide()
    },
    showEditModal (id) {
      getCategory(id).then(
        res => {
          if (res.data.success) {
            this.categoryID = id
            this.previewEditImage = res.data.data.imageUrl
            this.formControl = {
              active: res.data.data.active,
              posId: res.data.data.posId,
              nameZhHk: res.data.data.nameZhHk,
              nameZhCn: res.data.data.nameZhCn
            }
            this.$refs.editCategoryModal.show()
          }
        },
        err => {
          this.makeToast('danger', '發生錯誤，請稍後再試。', err)
        }
      )
    },
    hideEditModal () {
      this.resetformControl()
      this.$refs.editCategoryModal.hide()
    },
    resetformControl () {
      this.previewImage = null
      this.previewEditImage = null
      this.formControl = {
        active: true,
        image: null,
        posId: '',
        titleZhCn: '',
        nameZhHk: '',
        nameZhCn: ''
      }
    },
    showSortingModal (item) {
      this.selectedProSeries = item
      this.sortingOrder = item.sortingOrder
      this.$bvModal.show('setSortingOrder')
    },
    hideSortingModal () {
      this.$bvModal.hide('setSortingOrder')
      this.selectedProSeries = null
    },
    async submitChangeSorting (oldOrder, newOrder, id) {
      const items = this.categories.content
      const currentOrder = (this.filters.page * this.filters.size) + oldOrder
      const itemToUpdate = items.find(item => item.id === this.selectedProSeries.id)
      if (!itemToUpdate) return items

      itemToUpdate.sortingOrder = newOrder

      const updatedItems = items.map(item => {
        if (item.id !== itemToUpdate.id) {
          if (item.sortingOrder > currentOrder && item.sortingOrder <= newOrder) {
            return { productSeriesId: item.id, sortingOrder: item.sortingOrder - 1 }
          } else if (item.sortingOrder < currentOrder && item.sortingOrder >= newOrder) {
            return { productSeriesId: item.id, sortingOrder: item.sortingOrder + 1 }
          }
          return { productSeriesId: item.id, sortingOrder: item.sortingOrder }
        }
        return { productSeriesId: itemToUpdate.id, sortingOrder: item.sortingOrder }
      })
      await updateSortingOrderForProductSeries(updatedItems)
      this.getAll()
      this.hideSortingModal()
    },
    async handleDragEnd () {
      const orderNum = (this.filters.page * this.filters.size) + 1
      const sortedData = this.categories.content.map((x, index) => {
        return { productSeriesId: x.id, sortingOrder: index + orderNum }
      })
      await updateSortingOrderForProductSeries(sortedData)
      this.getAll()
    }
  }
}
</script>

<style scoped lang="scss">
.preview-image {
  width: 100%;
  height: 180px;
  background-size: cover;
  background-position: center center;
}

@media (min-width: 992px) {
  .preview-image {
    width: 218px;
    height: 160px;
  }
 }
</style>
